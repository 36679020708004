import { useState } from 'react'
import { uniq } from 'ramda'
import {
  Box,
  Checkbox,
  ClickAwayListener,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
  Chip,
  Grid,
} from '@mui/material'
import { ButtonLink, ButtonSecondary, InputSearchText } from 'components-new'
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { SearchMultiSelectPropsType, OptionsType } from './SearchMultiSelect.types'
import { checkboxStyles, labelStyles, footerStyles } from './SearchMultiSelect.styles'
import ButtonLink2 from '../Button/ButtonLink2'
import { useMeasure } from 'react-use'
import { ActiveRelationsModal, ModalData } from '../ActiveRelationsModal'
import { PersonListItem } from './ListItem'

const SELECT_ALL = 'all'
const NOT_EXPANDED_MAX_HEIGHT = 200

const SearchMultiSelectOld = ({
  title,
  searchText,
  setSearchText,
  options,
  selectedOptions,
  setSelectedOptions,
  isDisabled = false,
}: SearchMultiSelectPropsType) => {
  const [isListActive, setIsListActive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalListData, setModalListData] = useState<ModalData>()

  const [boxRef, { height }] = useMeasure<HTMLDivElement>()

  const handleCheck = (checked: boolean, { value, label, ...props }: OptionsType) => {
    if (checked) {
      if (value === SELECT_ALL) {
        setSelectedOptions(options)
      } else {
        setSelectedOptions((prevState) => [...prevState, { value, label, ...props }])
      }
    } else {
      if (value === SELECT_ALL) {
        if (selectedOptions.length === options.length) {
          setSelectedOptions([])
        } else {
          setSelectedOptions((prevState) =>
            prevState.filter((item) => item.value !== value)
          )
        }
      } else {
        setSelectedOptions((prevState) =>
          prevState
            .filter((item) => item.value !== value)
            .filter((item) => item.value !== SELECT_ALL)
        )
      }
    }
  }
  const isChecked = (list: OptionsType[], value: string) =>
    list.some((x) => x.value === value)

  const handleDeleteChip = (chipToDelete: OptionsType) => {
    setSelectedOptions((prevState) =>
      prevState.filter((option) => option.value !== chipToDelete.value)
    )
  }

  const handleClearAll = () => setSelectedOptions([])
  const handleApply = () => {
    setIsListActive(false)
    setSearchText('')
  }

  const selectedGrid = (isExpanded: boolean) => {
    return {
      maxHeight: isExpanded ? '100%' : NOT_EXPANDED_MAX_HEIGHT + 'px',
      overflow: 'hidden',
    }
  }

  const handleOpenModal = (activeRelations: ModalData) => {
    setModalListData(activeRelations)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setModalListData(undefined)
    }, 10) // means list does not close on modal close
  }

  const handleClickAway = () => {
    if (!modalListData) {
      setIsListActive(false)
    }
  }

  const filteredOptions = selectedOptions.filter((option) => option.value !== SELECT_ALL)

  return (
    <Stack height={1} spacing={3} sx={{ width: '100%' }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          {title && <Typography variant="subtitle2">{title}</Typography>}
          <InputSearchText
            value={searchText}
            handleChange={setSearchText}
            disabled={isDisabled}
            clickIn={() => setIsListActive(true)}
          />
          {isListActive && (
            <Paper sx={{ padding: 0 }}>
              <List
                component={Stack}
                spacing={4}
                sx={{ maxHeight: 300, padding: 4, overflow: 'auto' }}
              >
                {options &&
                  uniq(options)
                    .filter((item: OptionsType) =>
                      item.label.toLowerCase().includes(searchText.toLowerCase())
                    )
                    .map(({ value, label, activeRelations, ...props }: OptionsType) => (
                      <Stack key={value + label} direction="row">
                        <Checkbox
                          checked={isChecked(selectedOptions, value)}
                          onChange={(e) =>
                            handleCheck(e.target.checked, { value, label, ...props })
                          }
                          sx={checkboxStyles}
                        />
                        <ListItem sx={labelStyles}>
                          <PersonListItem
                            label={label}
                            activeRelations={activeRelations}
                            onOpenModal={handleOpenModal}
                          />
                        </ListItem>
                      </Stack>
                    ))}
              </List>
              <Stack sx={footerStyles}>
                <ButtonLink onClick={handleClearAll}>
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-clear-all')}
                  />
                </ButtonLink>
                <ButtonSecondary
                  sx={{ color: '#FFF !important' }}
                  disabled={!selectedOptions.length}
                  onClick={handleApply}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-apply')}
                  />
                </ButtonSecondary>
              </Stack>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>
      {!isListActive && (
        <Stack direction="column">
          <Grid ref={boxRef} container sx={selectedGrid(isExpanded)}>
            {filteredOptions.map((option) => {
              return (
                <Chip
                  key={JSON.stringify(option)}
                  label={
                    <Typography variant="body1">{`(${option.value}) ${option.label}`}</Typography>
                  }
                  deleteIcon={
                    <CloseIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                        margin: ' auto 12px auto auto !important',
                        // marginRight: '12px!important',
                      }}
                    />
                  }
                  onDelete={() => handleDeleteChip(option)}
                  color="primary"
                  sx={{
                    paddingX: 0,
                    paddingY: 1,
                    maxWidth: '100%',
                    marginRight: 2,
                    marginBottom: 2,
                    '.MuiChip-label': { fontSize: 16, fontWeight: 'normal' },
                  }}
                />
              )
            })}
          </Grid>
          <Stack direction="row" sx={{ display: 'inline-flex' }} spacing={1}>
            <Typography variant="body2">
              <FormattedMessage
                id={intl.generic('x-items-selected')}
                values={{ count: filteredOptions.length }}
              />
            </Typography>
            {height < NOT_EXPANDED_MAX_HEIGHT ? null : isExpanded ? (
              <ButtonLink2 onClick={() => setIsExpanded(false)}>
                <FormattedMessage id={intl.generic('view-less')} />
              </ButtonLink2>
            ) : (
              <ButtonLink2 onClick={() => setIsExpanded(true)}>
                <FormattedMessage id={intl.generic('view-all')} />
              </ButtonLink2>
            )}
          </Stack>
        </Stack>
      )}
      <ActiveRelationsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={modalListData?.name || ''}
        activeRelations={modalListData?.activeRelations}
      />
    </Stack>
  )
}

export default SearchMultiSelectOld
