import React from 'react'
import { Box, Typography, Modal, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { styles } from './ActiveRelationsModal.styles'
import { ActiveRelation } from '../SearchMultiSelectOld/SearchMultiSelect.types'

export type ModalData = {
  name: string
  activeRelations?: ActiveRelation[]
}

export type ActiveRelationsModalProps = {
  isOpen: boolean
  onClose: () => void
  title: string
  activeRelations?: ActiveRelation[]
}

export const ActiveRelationsModal = (props: ActiveRelationsModalProps) => {
  const { isOpen, onClose, title, activeRelations = [] } = props
  return (
    <Modal open={isOpen} onClose={onClose} data-cy="active-relations-modal">
      <Box sx={styles.main}>
        <Box sx={styles.title}>
          <Typography variant="h3" sx={styles.titleText}>
            {title} {<FormattedMessage id={intl.creditPolicy('active-relations')} />}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="large" sx={styles.closeButtonIcon} />
          </IconButton>
        </Box>

        <Box sx={styles.content}>
          {activeRelations?.map((relation) => (
            <Typography
              key={relation.local_organization_id.id}
              sx={styles.relationItem}
              onClick={() =>
                window.open(
                  `/creditcheck/${relation.local_organization_id.country}/${relation.local_organization_id.id}`,
                  '_blank'
                )
              }
            >
              {relation.name}
            </Typography>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}
