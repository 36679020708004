import { Address } from 'types/general'

/*
  TODO: we are using two different types for the same address field
  Company.Basics['address'] and Address
  this should be unified.
  https://linear.app/risika/issue/ENG2-1229/unify-types-for-address-in-generalcompanyinformation
*/

/**
 * Splits an address into its component sections for formatting purposes.
 *
 * @param address - The address object containing address details
 * @param address.coname - Optional care of name
 * @param address.country - Optional country name
 * @param address.number - Optional street number
 * @param address.postdistrict - Optional post district
 * @param address.street - Optional street name
 * @param address.zipcode - Optional postal code
 * @param address.city - Optional city name
 *
 * @returns An object containing formatted address sections
 * @returns {string} generatedCareOfName - Formatted 'care of' section with trailing comma if present
 * @returns {string} generatedStreetAndNumber - Formatted street and number section with trailing comma if present
 * @returns {string} generatedZipcodeAndPostdistrict - Formatted zipcode and post district/city section with trailing comma if present
 * @returns {string} generatedCountry - Formatted country section
 */
export const getAddressSections = (address: Address) => {
  const { coname, country, number, postdistrict, street, zipcode, city } = address
  const generatedCareOfName = coname ? `c/o ${coname}, ` : ''
  const generatedStreetAndNumber = (() => {
    if (street != null && number != null) {
      return `${street} ${number}, `
    } else if (street != null) {
      return `${street}, `
    } else {
      return ''
    }
  })()
  const generatedCountry = country != null ? country : ''
  const generatedZipcodeAndPostdistrict = (() => {
    const area = postdistrict || city
    if (zipcode != null && area != null) {
      return `${zipcode} ${area}, `
    } else if (zipcode != null) {
      return `${zipcode}${generatedCountry ? ', ' : ''}`
    } else if (area != null) {
      return `${area}`
    } else {
      return generatedCountry ? ' ' : ''
    }
  })()

  return {
    generatedCareOfName,
    generatedStreetAndNumber,
    generatedZipcodeAndPostdistrict,
    generatedCountry,
  }
}

export const handleAddressField = (address?: Address | null) => {
  if (!address) return ''
  const {
    generatedCareOfName,
    generatedStreetAndNumber,
    generatedZipcodeAndPostdistrict,
    generatedCountry,
  } = getAddressSections(address)
  const finalAddress = `${generatedCareOfName}${generatedStreetAndNumber}${generatedZipcodeAndPostdistrict}${generatedCountry}`
  if (finalAddress.endsWith(', ')) {
    return finalAddress.substring(0, finalAddress.length - 2)
  }
  return finalAddress
}

export const generateMapLocation = (address?: Address): string => {
  const { street, number, zipcode, city, postdistrict, municipality, country } =
    address || {}

  const streetNumber = `${street} ${number}`
  const cityOrDistrict = `${city !== postdistrict ? `, ${city}` : ''}`
  const composedStreet = `${streetNumber}${cityOrDistrict}`
  const composedDistrict = postdistrict
    ? `${zipcode} ${postdistrict} ${
        municipality && !postdistrict.includes(municipality) ? `(${municipality})` : ''
      }, ${country}`
    : null
  const addressLines = !street
    ? [null, composedDistrict]
    : [composedStreet, composedDistrict]
  return addressLines.join(' ')
}
