import * as React from 'react'
import { Stack } from '@mui/material'
import { SearchMultiSelectOld } from 'components-new'
import { searchPerson } from 'services/api'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { ExcludePersonsSet } from 'services/api/endpoints/risikaAPI/setSingleCreditPolicy'
import { ExtendedOptionsType } from 'components-new/inputs/SearchMultiSelectOld/SearchMultiSelect.types'
import { ReactSetState } from 'types/general'
import { PersonSearch } from 'services/api/endpoints/risikaAPI/searchPerson'
import { useMemo } from 'react'

const convertFromAPIData = (data: ExcludePersonsSet[] | null) => {
  if (!data) {
    return []
  }
  return data.map((person) => ({
    label: person.local_person_id.name,
    value: person.local_person_id.id,
    country: person.local_person_id.country,
  }))
}

const RejectPeople = ({ isAdvanced = true }) => {
  const { selectedCountry, policy, setPolicy, isInferiorPlan } = useCountryPolicy()
  const [data, setData] = React.useState<ExtendedOptionsType[]>([])
  const [searchText, setSearchText] = React.useState('')
  const [persons, setPersonsList] = React.useState(
    convertFromAPIData(policy.exclude_persons ?? [])
  )

  const sortedPersons = useMemo(() => {
    const personsSort = [...persons]?.sort((a, b) =>
      (a.label ?? '') > (b.label ?? '') ? 1 : -1
    )
    return personsSort
  }, [persons])

  React.useEffect(() => {
    if (
      JSON.stringify(convertFromAPIData(policy.exclude_persons)) !==
      JSON.stringify(persons)
    ) {
      setPersonsList(convertFromAPIData(policy.exclude_persons))
    }
  }, [policy.exclude_persons])

  React.useEffect(() => {
    if (searchText?.length > 3) {
      searchPerson({ searchText, country: selectedCountry }).then(({ search_result }) => {
        if (search_result) {
          setData(convertToDropdownData(search_result))
        }
      })
    }
  }, [searchText])

  React.useEffect(() => {
    if (
      JSON.stringify(convertFromAPIData(policy.exclude_persons)) !==
      JSON.stringify(persons)
    ) {
      setPolicy({
        ...policy,
        exclude_persons: persons.map((person) => ({
          local_person_id: {
            id: person.value,
            name: person.label,
            country: person.country || 'DK',
          },
        })),
      })
    }
  }, [persons, setPolicy])

  const convertToDropdownData = (data: PersonSearch['search_result']) => {
    return data.map((item) => {
      return {
        value: item.personal_id as string,
        label: item.name,
        country: item.country,
        activeRelations: item.active_relations,
      }
    })
  }

  return (
    <Stack data-cy="company-type-multi-select">
      <SearchMultiSelectOld
        searchText={searchText}
        setSearchText={setSearchText}
        options={data}
        selectedOptions={sortedPersons as ExtendedOptionsType[]}
        setSelectedOptions={setPersonsList as ReactSetState<ExtendedOptionsType[]>}
        isDisabled={isAdvanced && isInferiorPlan}
      />
    </Stack>
  )
}

export default RejectPeople
