import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const inputStyles: SxProps = {
  borderColor: 'grey.200',
  input: {
    padding: '12px 6px',
  },
  width: '100%',
}

const checkboxStyles = {
  svg: {
    height: '16px',
    width: '16px',
  },
}

const labelStyles: SxProps = {
  padding: 0,
  paddingLeft: 2,
}
const footerStyles: SxProps = {
  paddingY: 3,
  paddingX: 4,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderTop: '1px solid',
  borderColor: 'grey.200',
  backgroundColor: 'grey.50',
  a: {
    textDecoration: 'underline',
  },
}

const buttonStyle = {
  fontSize: '1rem',
  marginLeft: 12,
  backgroundColor: theme.palette.grey[200],
  padding: 6,
  borderRadius: 4,
}

const listItemContainer = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}

export {
  inputStyles,
  checkboxStyles,
  labelStyles,
  footerStyles,
  buttonStyle,
  listItemContainer,
}
