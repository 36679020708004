import { Box, Typography, Stack } from '@mui/material'
import HumanDate from 'components/ui/HumanDate'
import moment from 'moment'
import { classes } from './Changes.styles'

// Define props separately
type FraudIndicatorProps = {
  change: any
  riskDataPoints: any[]
  reactIntl: any
  intl: any
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
}

// Component
export const FraudIndicatorChange = (props: FraudIndicatorProps) => {
  const { change, riskDataPoints, reactIntl, intl, isExpanded, setIsExpanded } = props
  if (!riskDataPoints.length) return null

  return (
    <Box sx={classes.root}>
      <Typography variant="subtitle1">
        {reactIntl.formatMessage({
          id: intl.fraudIndicators('risk-indicators-sentence'),
        })}
      </Typography>
      <Typography variant="body2" className="text-grey-dark">
        <HumanDate date={moment(change.date)} />
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{ cursor: riskDataPoints.length > 2 ? 'pointer' : 'default' }}
        mt={2}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {riskDataPoints}
      </Stack>
    </Box>
  )
}
