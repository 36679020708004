import { AvailableCountries } from '../../../globalTypes'
import { CSSProperties } from 'react'
import {
  DE,
  DK,
  FI,
  GB,
  NO,
  SE,
  NL,
  BE,
  IS,
  PL,
  LT,
  LV,
  EE,
} from 'country-flag-icons/react/3x2'

const CountryFlagIcon = ({
  country,
  styles = {},
}: {
  country: AvailableCountries
  styles?: CSSProperties
}) => {
  const iconStyles = { ...{ height: '20px', borderRadius: 2 }, ...styles }

  // TODO, make this dynamic

  switch (country.toUpperCase()) {
    case 'DK':
      return <DK style={iconStyles} />
    case 'SE':
      return <SE style={iconStyles} />
    case 'NO':
      return <NO style={iconStyles} />
    case 'FI':
      return <FI style={iconStyles} />
    case 'UK':
      return <GB style={iconStyles} />
    case 'DE':
      return <DE style={iconStyles} />
    case 'NL':
      return <NL style={iconStyles} />
    case 'BE':
      return <BE style={iconStyles} />
    case 'LT':
      return <LT style={iconStyles} />
    case 'LV':
      return <LV style={iconStyles} />
    case 'EE':
      return <EE style={iconStyles} />
    case 'IS':
      return <IS style={iconStyles} />
    case 'PL':
      return <PL style={iconStyles} />
    default:
      break
  }
}

export default CountryFlagIcon
