import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { ChangeType } from './Change.types'
import { classes } from './Changes.styles'

type InDepthDateInoProps = {
  change: ChangeType
  isMoreInfoVisible: boolean
}

export const InDepthDateInfo = (props: InDepthDateInoProps) => {
  const { change, isMoreInfoVisible } = props

  if (!isMoreInfoVisible) {
    return null
  }

  return (
    <Box>
      {change.new.valid_from && (
        <Typography variant="body2" sx={classes.inDepthDate}>
          <FormattedMessage id={intl.riskMonitoringNew('valid_from')} />
          {change.new.valid_from}
        </Typography>
      )}
      {change.new.valid_to && (
        <Typography variant="body2" sx={classes.inDepthDate}>
          <FormattedMessage id={intl.riskMonitoringNew('valid_to')} />
          {change.new.valid_to}
        </Typography>
      )}
    </Box>
  )
}
