const root = {
  backgroundColor: 'common.white',
  padding: 2,
  boxShadow: 1,
  borderRadius: 1,
}
const change = {
  display: 'flex',
}

const changeContent = {
  padding: '0.25rem 0.5rem',
  backgroundColor: 'grey.200',
  borderRadius: 2,
}
const changeArrow = {
  margin: '0.25rem 0.5rem',
}

const paddingAfterDays = {
  pb: 2,
}
const probability = {
  py: 2,
}

const inDepthDate = {
  backgroundColor: 'grey.200',
  borderRadius: 2,
  padding: 1,

  flex: 1,
  maxWidth: 'fit-content',
  marginBottom: 2,
}

export const classes = {
  root,
  change,
  changeContent,
  changeArrow,
  paddingAfterDays,
  probability,
  inDepthDate,
}
