import { Stack } from '@mui/system'
import Change from 'components-new/data-display/Table/elements/Changes/Change'
import { getRestructureChanges } from 'components-new/data-display/Table/elements/Changes/Changes.controller'

// Non ECP table changes
const Changes = ({ row }) => {
  const changes = getRestructureChanges({ changes: row.original.changes })

  return (
    <Stack direction="row" spacing={6}>
      {changes.map((change, i) => (
        <Change key={`${row.original.localId.id}-${i}`} change={change} />
      ))}
    </Stack>
  )
}

export default Changes
