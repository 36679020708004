export const styles = {
  main: {
    borderRadius: '6px',
    display: 'flex',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column' as 'column',
  },
  title: {
    backgroundColor: 'primary.dark',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 4,
    paddingLeft: 4,
  },
  titleText: {
    color: 'common.white',
  },
  content: {
    maxHeight: 400,
    overflowY: 'auto',
    margin: 4,
  },
  relationItem: {
    padding: 2,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  closeButtonIcon: { color: 'grey.500' },
}
