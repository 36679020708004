import { Moment } from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import intl from 'localization/components'

const dateFormat = (date: Moment, format: 'relative' | 'duration' | string) => {
  if (format === 'relative') {
    return date.fromNow()
  }

  if (format === 'duration') {
    return date.fromNow(true)
  }

  return date.format(format)
}
/**
 * A localized date.
 *
 * @param {moment} date A moment date object.
 * @param {string} format The format to use. Defaults to `relative`.
 * @param {function} children A function that receives the formatted date as a string.
 */

type HumanDateProps = {
  children?: (date: string) => JSX.Element
  date: Moment
  format?: 'relative' | 'duration' | string
}

const HumanDate = ({ children, date, format = 'relative' }: HumanDateProps) => {
  const reactIntl = useIntl()

  date.locale(reactIntl?.locale)

  if (children && typeof children === 'function') {
    return children(dateFormat(date, format))
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <FormattedMessage id={intl.riskMonitoringNew('last-updated-at')} />
      &nbsp;
      <Typography variant="body2">{dateFormat(date, format)}</Typography>
    </div>
  )
}

export default HumanDate
