import * as React from 'react'
import { Stack } from '@mui/material'
import { searchCompany } from 'services/api'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { SearchMultiSelectOld } from 'components-new'
import { SearchResultsType } from 'services/api/endpoints/risikaAPI/searchCompany'
import { ExcludeCompaniesSet } from 'services/api/endpoints/risikaAPI/setSingleCreditPolicy'
import { ExtendedOptionsType } from 'components-new/inputs/SearchMultiSelectOld/SearchMultiSelect.types'

const convertFromAPIData = (
  data: ExcludeCompaniesSet[] | null
): ExtendedOptionsType[] => {
  if (!data) {
    return []
  }
  return data.map((company) => ({
    label: company.local_organization_id.name ?? '',
    value: company.local_organization_id.id,
    country: company.local_organization_id.country,
  }))
}

const RejectCompany = ({ isAdvanced = true }) => {
  const { selectedCountry, policy, setPolicy, isInferiorPlan } = useCountryPolicy()
  const [data, setData] = React.useState<ReturnType<typeof convertToDropdownData>>([])
  const [searchText, setSearchText] = React.useState('')
  const [companies, setCompaniesList] = React.useState(
    convertFromAPIData(policy.exclude_companies) ?? []
  )

  React.useEffect(() => {
    if (
      JSON.stringify(convertFromAPIData(policy.exclude_companies)) !==
      JSON.stringify(companies)
    ) {
      setCompaniesList(convertFromAPIData(policy.exclude_companies))
    }
  }, [policy.exclude_companies])

  React.useEffect(() => {
    if (searchText?.length > 2) {
      searchCompany({
        searchText: searchText,
        model: 'BOHR',
        searchCountry: selectedCountry,
      }).then(({ search_result }) => {
        if (search_result) {
          setData(convertToDropdownData(search_result))
        }
      })
    }
  }, [searchText])
  React.useEffect(() => {
    if (
      JSON.stringify(convertFromAPIData(policy.exclude_companies)) !==
      JSON.stringify(companies)
    ) {
      setPolicy({
        ...policy,
        exclude_companies: companies.map((company) => ({
          local_organization_id: {
            id: company.value,
            country: company.country || selectedCountry,
            name: company.label,
          },
        })),
      })
    }
  }, [companies, setPolicy])

  const convertToDropdownData = (data: SearchResultsType[]) => {
    return data.map((item) => ({
      value: item.local_organization_id.id,
      label: item.company_name,
      country: item.local_organization_id.country,
    }))
  }

  return (
    <Stack data-cy="company-type-multi-select">
      <SearchMultiSelectOld
        searchText={searchText}
        setSearchText={setSearchText}
        options={data as ExtendedOptionsType[]}
        selectedOptions={companies.sort((a, b) => (a.label > b.label ? 1 : -1))}
        setSelectedOptions={setCompaniesList}
        isDisabled={isAdvanced && isInferiorPlan}
      />
    </Stack>
  )
}

export default RejectCompany
