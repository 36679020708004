import { useState, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Hide, HumanDate, Show } from 'components'
import moment from 'moment'
import { navigation } from 'services/navigation'
import { Link } from 'react-router-dom'
import { isNil } from 'ramda'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import intl from 'localization/components'
import { classes } from './Changes.styles'
import {
  ChangeType,
  PaymentRemarkChange,
  RestructuredChangeType,
  ScoreChange,
  FraudIndicatorDataPointType,
} from './Change.types'
import {
  isFraudIndicatorType,
  getTotalProps,
  handleChangeValue,
  DateInfoToggle,
} from './Changes.controller'
import { typeGuard } from 'utils/general'
import { FraudIndicatorChange } from './FraudIndicatorChange'
import { InDepthDateInfo } from './InDepthDateInfo'

type LinkWrapType = {
  data: RestructuredChangeType
  children: React.ReactNode
}
const LinkWrap = ({ data, children }: LinkWrapType) =>
  data ? (
    <Link to={navigation.relationOverview(data)} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )

const Change = ({ change }: { change: RestructuredChangeType }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isMoreInfoVisible, setIsMoreInfoVisible] = useState(false)
  // @ts-ignore // This is just because redux is going to be deprecated anyway
  const { plan } = useSelector((state) => state.risika.permissions.company)
  const reactIntl = useIntl()

  const canShowChange = (change: ChangeType) => {
    if (plan === 'FREEMIUM') return false

    return change?.type?.toLowerCase() !== 'score'
  }

  const riskDataPoints = useMemo(() => {
    if (isFraudIndicatorType(change)) {
      const generatePlusOne = () => {
        return (
          <Typography
            key={`plus-1-${change.type}`}
            data-cy={`fraud-indicator-plus-one`}
            variant="body2"
            sx={classes.changeContent}
          >
            +1
          </Typography>
        )
      }
      const generateRiskIndicator = (key: FraudIndicatorDataPointType) => {
        return (
          <Typography
            key={key}
            data-cy={`fraud-indicator-${key}`}
            variant="body2"
            sx={classes.changeContent}
          >
            {change.dataPoints?.[key]}
          </Typography>
        )
      }

      const newDataPoints = Object.keys(change.dataPoints ?? {}).map(
        (key) => key as FraudIndicatorDataPointType
      )

      if (isExpanded) {
        return newDataPoints.map(generateRiskIndicator)
      }
      if (newDataPoints.length > 2) {
        return [
          ...newDataPoints.slice(0, 2).map(generateRiskIndicator),
          generatePlusOne(),
        ]
      }
      return newDataPoints.map(generateRiskIndicator)
    }
    return []
  }, [change, isExpanded])

  if (!change) return null

  const total = getTotalProps(change)

  if (isFraudIndicatorType(change)) {
    return (
      <FraudIndicatorChange
        change={change}
        riskDataPoints={riskDataPoints}
        reactIntl={reactIntl}
        intl={intl}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
    )
  }

  return (
    <Box sx={classes.root}>
      {!isNil(typeGuard('to', change)) ? (
        <>
          <Box sx={classes.paddingAfterDays}>
            <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
              {change.sentence}
            </Typography>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
              <HumanDate date={moment(change.date)} />
              <DateInfoToggle
                change={change}
                onClick={() => setIsMoreInfoVisible(!isMoreInfoVisible)}
                isActive={isMoreInfoVisible}
              />
            </Typography>
            <InDepthDateInfo isMoreInfoVisible={isMoreInfoVisible} change={change} />
          </Box>
          {canShowChange(change) ? (
            <Box>
              <Box sx={classes.change}>
                {!isNil(change.from) && (
                  <>
                    {/* @ts-ignore No clue where this is coming from */}
                    <LinkWrap data={change.to.personal_id}>
                      <Typography
                        variant="body2"
                        sx={{
                          ...classes.changeContent,
                          // @ts-ignore No clue where this is coming from
                          ...change?.from?.personal_id,
                        }}
                      >
                        {handleChangeValue(change.from)}
                      </Typography>
                    </LinkWrap>
                    <Typography variant="body2" sx={classes.changeArrow}>
                      →
                    </Typography>
                  </>
                )}
                {/* @ts-ignore No clue where this is coming from */}
                <LinkWrap data={change.to.personal_id}>
                  <Typography
                    variant="body2"
                    sx={{
                      ...classes.changeContent,
                      // @ts-ignore No clue where this is coming from
                      ...change.to.personal_id,
                    }}
                  >
                    {handleChangeValue(change.to)}
                  </Typography>
                </LinkWrap>
              </Box>
              <Hide when={change?.type !== 'newton'}>
                <Box>
                  <Typography variant="subtitle1" sx={classes.probability}>
                    <FormattedMessage id={intl.financial('probability-of-closure')} />
                  </Typography>
                  <Box sx={classes.change}>
                    <Typography variant="body2" sx={classes.changeContent}>
                      <Show
                        when={
                          !!(change?.old?.value as ScoreChange)?.probability_of_distress
                        }
                      >
                        <FormattedNumber
                          value={
                            (change?.old?.value as ScoreChange)?.probability_of_distress!
                          }
                          maximumFractionDigits={2}
                          style="percent"
                        />
                      </Show>
                    </Typography>
                    <Typography variant="body2" sx={classes.changeArrow}>
                      →
                    </Typography>
                    <Typography variant="body2" sx={classes.changeContent}>
                      <Show
                        when={
                          !!(change?.old?.value as ScoreChange)?.probability_of_distress
                        }
                      >
                        <FormattedNumber
                          value={
                            (change?.old?.value as ScoreChange)?.probability_of_distress!
                          }
                          maximumFractionDigits={2}
                          style="percent"
                        />
                      </Show>
                    </Typography>
                  </Box>
                </Box>
              </Hide>
            </Box>
          ) : null}
          {total && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              mt={2}
            >
              {(total as PaymentRemarkChange)?.unique_creditors && (
                <Typography variant="body2" sx={classes.changeContent}>
                  <FormattedMessage id={intl.paymentRemarks('unique-creditors')} />
                  :&nbsp;
                  {(total as PaymentRemarkChange).unique_creditors}
                </Typography>
              )}
              {(total as PaymentRemarkChange)?.total_unsettled && (
                <Typography variant="body2" sx={classes.changeContent}>
                  <FormattedMessage id={intl.paymentRemarks('total-unsettled-number')} />
                  :&nbsp;
                  {(total as PaymentRemarkChange).total_unsettled}
                </Typography>
              )}
              {(total as PaymentRemarkChange)?.total_unsettled_amount && (
                <Typography variant="body2" sx={classes.changeContent}>
                  <FormattedMessage id={intl.paymentRemarks('total-unsettled-amount')} />
                  :&nbsp;
                  {new Intl.NumberFormat('no-NO', {
                    style: 'decimal',
                  }).format((total as PaymentRemarkChange)?.total_unsettled_amount!) +
                    ' NOK'}
                </Typography>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Box>
          <Typography variant="subtitle1">{change.sentence}</Typography>
          <Typography variant="body2" className="text-grey-dark">
            {/* @ts-ignore No clue where this is coming from */}
            <HumanDate date={moment(change.date)} />
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Change
