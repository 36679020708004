import * as React from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { ActionDialog } from 'components'
import { TextField, Grid, Switch, Typography } from '@mui/material'
import SelectUsers from './SelectUsers'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTotangoTracking from 'utils/totangoTracking'
import { useMonitorListPost } from 'services/queries'
import { ButtonTertiary } from 'components-new'
import { Mixpanel } from 'services/helpers/mixpanel'

export const AddNewList = ({ currentUserInfo, customListButton }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useSelector((state) => state.auth.user.data)

  const [listName, setListName] = React.useState('')
  const [selectedUsers, setSelectedUsers] = React.useState([])
  const [isPublic, setIsPublic] = React.useState(false)

  const { trackEvent } = useTotangoTracking()
  const { mutate: createList } = useMonitorListPost()

  const addNewList = () => {
    if (!listName) {
      enqueueSnackbar(
        <FormattedMessage id={intl.riskMonitoringNew('add-list-name-snackbar')} />
      )
    } else {
      createList({
        title: listName,
        ownerApiId: currentUserInfo.api_user_id,
        users: selectedUsers,
        isPublic,
      })
      trackEvent('Monitoring', 'New List')
      Mixpanel.track('User created new list', { title: listName, public: isPublic })
      setListName('')
      // queryClient.invalidateQueries(MONITOR_LIST)
    }
  }

  return (
    <div>
      <ActionDialog
        title={
          <FormattedMessage id={intl.riskMonitoringNew('create-new-list-text-field')} />
        }
        disabled={listName.length < 3}
        content={(close) => (
          <React.Fragment>
            <TextField
              data-cy="add-monitor-list-input"
              type="text"
              label={<FormattedMessage id={intl.riskMonitoringNew('add-name')} />}
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              autoFocus
              fullWidth
              onFocus={(event) => {
                event.target.select()
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && listName.length > 2) {
                  addNewList()
                  close()
                }
              }}
              margin="normal"
              variant="outlined"
            />
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Typography>
                  <FormattedMessage id={intl.riskMonitoringNew('new-list-private')} />
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={isPublic}
                  onChange={() => setIsPublic(!isPublic)}
                  data-cy="is-public-toggle"
                />
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage id={intl.riskMonitoringNew('new-list-public')} />
                </Typography>
              </Grid>
            </Grid>
            {!isPublic ? (
              <SelectUsers
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                ownerId={id}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )}
        action={addNewList}
        maxWidth="sm"
        fullWidth={true}
        render={(open) =>
          customListButton ? (
            customListButton(open)
          ) : (
            <ButtonTertiary
              data-cy="add-monitor-list"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{
                    fontSize: '1.5rem',
                    marginRight: '0.5rem',
                  }}
                />
              }
              onClick={open}
            >
              <FormattedMessage id={intl.riskMonitoringNew('add-new-list')} />
            </ButtonTertiary>
          )
        }
        agreeText={<FormattedMessage id={intl.riskMonitoringNew('create-list-button')} />}
      />
    </div>
  )
}

export default AddNewList
