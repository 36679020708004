import { Paper, Box, Grid, Typography, CircularProgress, Tooltip } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import { theme } from 'styles-new/mui5-transition/theme'
import { navigation } from 'services/navigation'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import intl from 'localization/components'
import { useAccountStatus } from 'services/queries'
import { AnalyticsPropsType } from './Analytics.types'
import { classes } from './Analytics.styles'
import { classCircularProgress } from 'styles-new/global/components'
import { ButtonSecondary } from 'components-new'
import { useHistory } from 'react-router-dom'

const Analytics = (props: AnalyticsPropsType) => {
  const {
    selectedList,
    paginatedListLoading,
    isUsingCreditAutomator,
    selectedListIsLoading,
  } = props

  const blankEntries = { HIGH: 0, MEDIUM: 0, LOW: 0, NONE: 0 }
  const entries = selectedList?.risk_assessment ?? blankEntries
  const listId = selectedList?.list_id ?? 0
  const history = useHistory()

  const accountStatusQuery: any = useAccountStatus()
  const countMonitoring = accountStatusQuery.data?.counters.monitoring_count
  const totalMonitoring = accountStatusQuery.data?.caps.monitoring_count
  const remainingMonitoring =
    countMonitoring != null ? totalMonitoring - countMonitoring : 0

  if (!entries || accountStatusQuery.isLoading) {
    return (
      <Box sx={classCircularProgress}>
        <CircularProgress />
      </Box>
    )
  }

  const observationalListsRight =
    accountStatusQuery?.data?.rights?.global?.observational_lists ?? false

  const entriesTotal = Object.values(entries).reduce((accumulator, value) => {
    return accumulator + value
  }, 0)

  const data = [
    {
      key: 'total',
      label: intl.riskMonitoringOverview('total'),
      value: entriesTotal,
      color: theme.palette.grey[800],
      class: classes.analyticsItem,
    },
    {
      key: 'high-risk',
      label: intl.riskMonitoringOverview('high-risk'),
      value: entries.HIGH,
      color: theme.palette.error.main,
      class: classes.analyticsItem,
    },
    {
      key: 'medium-risk',
      label: intl.riskMonitoringOverview('medium-risk'),
      value: entries.MEDIUM,
      color: theme.palette.warning.dark,
      class: classes.analyticsItem,
    },
    {
      key: 'low-risk',
      label: intl.riskMonitoringOverview('low-risk'),
      value: entries.LOW,
      color: theme.palette.success.dark,
      class: classes.analyticsItemSpecial,
    },
    {
      key: 'no-score',
      label: intl.riskMonitoringOverview('no-score'),
      value: entries.NONE,
      color: theme.palette.grey[600],
      class: classes.analyticsItem,
    },
  ]

  let analyticButtonStyle: any = {
    ...classes.analyticsButtonContainer,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }

  let tooltipText = (
    <FormattedMessage id={intl.riskMonitoring('intelligence-suite-tooltip-access')} />
  )
  if (!entriesTotal || !observationalListsRight) {
    analyticButtonStyle = {
      ...classes.analyticsButtonContainer,
      ...classes.analyticsButtonDisabled,
    }
  }
  if (!observationalListsRight) {
    tooltipText = (
      <FormattedMessage
        id={intl.riskMonitoring('intelligence-suite-tooltip-no-access')}
      />
    )
  }
  return (
    <Paper sx={classes.analytics}>
      <Grid container spacing={0} sx={classes.grid}>
        <Grid item xs={6} md={3} lg={3}>
          <Typography variant="subtitle1">
            <FormattedNumber value={remainingMonitoring} />
          </Typography>
          <Typography>
            <FormattedMessage id={intl.riskMonitoring('remaining-monitoring')} />
          </Typography>
        </Grid>
        {data.map((item) => {
          return (
            <Grid key={item.key} item xs={6} md={3} lg sx={item.class}>
              <Typography variant="subtitle1">{item.value ?? 0}</Typography>
              <Typography sx={{ color: item.color }}>
                <FormattedMessage id={intl.riskMonitoringOverview(item.key)} />
              </Typography>
            </Grid>
          )
        })}
        <Grid item xs={12} md={6} lg={3} sx={classes.analyticsButtonGrid}>
          {(isUsingCreditAutomator ? !paginatedListLoading : !selectedListIsLoading) ? (
            <Tooltip title={tooltipText} arrow placement="bottom">
              <ButtonSecondary
                data-cy="risk-intelligence-suite-button"
                startIcon={<BarChartIcon />}
                onClick={() => {
                  if (observationalListsRight) {
                    history.push(navigation.observationalLists(listId))
                  }
                }}
                sx={analyticButtonStyle}
              >
                <FormattedMessage id={intl.riskMonitoring('intelligence-suite')} />
              </ButtonSecondary>
            </Tooltip>
          ) : (
            <Box sx={analyticButtonStyle}>
              <CircularProgress size={30} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Analytics
