import React from 'react'
import { Typography, Stack, TypographyVariant } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ModalData } from '../ActiveRelationsModal'
import { buttonStyle, listItemContainer } from './SearchMultiSelect.styles'
import { ActiveRelation } from './SearchMultiSelect.types'

type ActiveRelationsDisplayProps = {
  label: string
  activeRelations?: ActiveRelation[]
  onOpenModal: (data: ModalData) => void
}

export const PersonListItem = (props: ActiveRelationsDisplayProps) => {
  const { label, activeRelations, onOpenModal } = props
  return (
    <Typography
      variant={'checkboxSmallLabel' as TypographyVariant}
      sx={listItemContainer}
    >
      {label}
      {activeRelations?.length === 1 && (
        <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
          {activeRelations.map((relation) => (
            <button
              key={relation.local_organization_id.id}
              onClick={() =>
                window.open(
                  `/creditcheck/${relation.local_organization_id.country}/${relation.local_organization_id.id}`,
                  '_blank'
                )
              }
              style={buttonStyle}
            >
              {relation.name}
            </button>
          ))}
        </Stack>
      )}

      {activeRelations && activeRelations?.length > 1 && (
        <button
          style={buttonStyle}
          onClick={() => onOpenModal({ activeRelations, name: label })}
        >
          <FormattedMessage id={intl.creditPolicy('multiple-active-relations')} />
        </button>
      )}
    </Typography>
  )
}
