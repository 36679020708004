import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'

import { chartDefinition } from './KeyFiguresRadarChartBox.model'
import { chartData } from './KeyFiguresRadarChartBox.model'

import BoxContainer from 'components-new/layouts/BoxContainer'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import KeyFiguresRadarChart from 'components-new/charts/KeyFiguresRadarChart'

import { useRatingScores } from 'services/queries'
import { useFinancialPerformance } from 'services/queries'
import { useFinancialRatios } from 'services/queries'

import { PropTypes, Labels, Ratio } from './KeyFiguresRadarChartBox.types'
import { classes } from './KeyFiguresRadarChartBox.styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type DataItem =
  | {
      key: string
      class: Labels
      value: number
      type: string
      name: string
    }
  | {
      key: string
      class: Labels
      value: number
      duration: string
      distress: number
      type: string
      name: string
    }

const getScreenshotData = (arr: DataItem[]): DataItem[] =>
  arr.map((item, index) => (index === 1 || index === 3 ? { ...item, key: '' } : item))

const KeyFiguresRadarChartBox = ({ localId, isCapturing }: PropTypes) => {
  const { messages, formatMessage } = useIntl()
  const ratingScoresQuery = useRatingScores(localId, 60000)
  const financialPerformanceQuery = useFinancialPerformance(localId, 60000)
  const financialRatiosQuery = useFinancialRatios(localId, 60000)

  if (
    ratingScoresQuery.isLoading ||
    financialPerformanceQuery.isLoading ||
    financialRatiosQuery.isLoading
  ) {
    return <CircularProgress />
  }

  const boxData = {
    title: intl.keyFigures('title'),
    headerElementRight: (
      <Tooltip
        enterTouchDelay={200}
        title={formatMessage({ id: intl.charts('radar-key-figures-info') })}
        arrow
      >
        <IconButton sx={classes.infoTooltip}>
          <InfoOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    ),
    status: {
      isLoading:
        ratingScoresQuery.isLoading ||
        financialPerformanceQuery.isLoading ||
        financialRatiosQuery.isLoading,
      isError:
        ratingScoresQuery.isError ||
        financialPerformanceQuery.isError ||
        financialRatiosQuery.isError,
    },
    height: 400,
    skeletonType: 'PIE',
  }

  const data = chartData.map((chartItem) => {
    if (chartItem.type === 'ratio') {
      const ratioValue =
        financialRatiosQuery.data && financialRatiosQuery.data.length > 0
          ? financialRatiosQuery?.data[0][chartItem.name as Ratio]
          : 0
      return {
        ...chartItem,
        key: messages[chartItem.key] as string,
        class: chartItem.class as Labels,
        value: Math.round((ratioValue ?? 0) * 100),
      }
    }
    const scoreValue = !ratingScoresQuery.data?.current_score
      ? { score: 0, date: '', probability_of_distress: 0 }
      : ratingScoresQuery.data.current_score
    return {
      ...chartItem,
      key: messages[chartItem.key] as string,
      class: chartItem.class as Labels,
      value: (scoreValue.score ?? 0) * 10,
      duration: scoreValue.date ?? '',
      distress: scoreValue.probability_of_distress ?? 0,
    }
  })

  const activeData = isCapturing ? getScreenshotData(data) : data

  return (
    <BoxContainer boxData={boxData}>
      {!boxData.status.isLoading && !boxData.status.isError ? (
        <Box sx={{ position: 'relative' }}>
          {activeData.map((item) => {
            return (
              <Typography variant="subtitle1" sx={classes[item.class]} key={item.key}>
                {item.key}
              </Typography>
            )
          })}
          <KeyFiguresRadarChart data={data} chart={chartDefinition} />
        </Box>
      ) : null}
    </BoxContainer>
  )
}

export default KeyFiguresRadarChartBox
