export const globalFlags = [
  {
    label: 'country.dk',
    value: 'DK',
  },
  {
    label: 'country.se',
    value: 'SE',
  },
  {
    label: 'country.no',
    value: 'NO',
  },
  {
    label: 'country.fi',
    value: 'FI',
  },
  {
    label: 'country.uk',
    value: 'UK',
  },
  {
    label: 'country.de',
    value: 'DE',
  },
  {
    label: 'country.nl',
    value: 'NL',
  },
  {
    label: 'country.be',
    value: 'BE',
  },
  {
    label: 'country.ee',
    value: 'EE',
  },
  {
    label: 'country.lv',
    value: 'LV',
  },
  {
    label: 'country.lt',
    value: 'LT',
  },
]

export const globalCountries = [
  'DK',
  'SE',
  'NO',
  'UK',
  'FI',
  'DE',
  'NL',
  'BE',
  'IS',
  'PL',
  'EE',
  'LV',
  'LT',
]
