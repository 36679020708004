import intl from 'localization/components'

export const countries = [
  {
    label: intl.country('dk'),
    value: 'DK',
  },
  {
    label: intl.country('se'),
    value: 'SE',
  },
  {
    label: intl.country('no'),
    value: 'NO',
  },
  {
    label: intl.country('fi'),
    value: 'FI',
  },
  {
    label: intl.country('uk'),
    value: 'UK',
  },
  {
    label: intl.country('de'),
    value: 'DE',
  },
  {
    label: intl.country('nl'),
    value: 'NL',
  },
  {
    label: intl.country('be'),
    value: 'BE',
  },
  {
    label: intl.country('lv'),
    value: 'LV',
  },
  {
    label: intl.country('lt'),
    value: 'LT',
  },
  {
    label: intl.country('ee'),
    value: 'EE',
  },
  {
    label: intl.country('is'),
    value: 'IS',
  },
  {
    label: intl.country('pl'),
    value: 'PL',
  },
]
