import { useQuery, UseQueryResult } from 'react-query'
import { MONITOR_LIST } from './constants'
import { getAxiosData } from '../useAccountStatus'
import { AxiosError } from 'axios'
import getMonitorList from 'services/api/endpoints/risikaAPI/newMonitoring/getMonitorLists'
import { MonitoringList } from 'types/queries'

const useLists = (): UseQueryResult<MonitoringList[], AxiosError<Error>> => {
  return useQuery<MonitoringList[], AxiosError<Error>>(
    MONITOR_LIST,
    () => getAxiosData(getMonitorList()),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export { useLists }
