import { Box, Stack } from '@mui/material'
import EstimationBox from 'widgets/charts/EstimationBox'
import HistoricalScoresBox from 'widgets/charts/HistoricalScoresBox'
import HighlightsBox from 'widgets/info/HighlightsBox'
import KeyFiguresRadarChartBox from 'widgets/charts/KeyFiguresRadarChartBox'
import { LocalId } from 'globalTypes'
import styles from './CompanyInfoColumns.styles'

const { columnContainer } = styles

const CompanyInfoColumns = ({
  companies,
  isCapturing,
}: {
  companies: LocalId[]
  isCapturing: boolean
}) => {
  return (
    <Stack direction="row">
      {companies.map((localId) => (
        <Stack key={localId.id} sx={columnContainer}>
          <Box data-cy="estimation-box" data-name={`estimation-box-${localId.id}`}>
            <EstimationBox localId={localId} />
          </Box>
          <Box
            data-cy="historical-scores-box"
            data-name={`historical-scores-box-${localId.id}`}
          >
            <HistoricalScoresBox localId={localId} comparison />
          </Box>
          <Box data-cy="highlights-box" data-name={`highlights-box-${localId.id}`}>
            <HighlightsBox localId={localId} />
          </Box>
          <Box
            data-cy="key-figures-radar-chart-box"
            data-name={`key-figures-radar-chart-box-${localId.id}`}
          >
            <KeyFiguresRadarChartBox localId={localId} isCapturing={isCapturing} />
          </Box>
        </Stack>
      ))}
    </Stack>
  )
}

export default CompanyInfoColumns
