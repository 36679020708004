import { AxiosPromise } from 'axios'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { MonitoringList } from 'types/queries'

const getMonitorList = (): AxiosPromise<MonitoringList[]> => {
  return risikaAPI.get(`/list/show`, {
    microservice: 'monitor',
  })
}

export default getMonitorList
