/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDebounce } from 'react-use'
import FlagSelect from 'components-new/elements/FlagSelect'
import Selector from './Selector'
import { setSearchResults, setSearchPerson } from 'store_deprecated'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { useCompanySearch } from 'services/queries/useCompanySearch'
import { usePersonSearch } from 'services/queries/usePersonSearch'
import { useAppShell } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { useUserSettingsField } from 'services/queries'
import { AvailableCountries } from 'globalTypes'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import {
  CloseSearch,
  CloseSearchIcon,
  Root,
  SearchField,
} from 'styles/components/searchbar'
import { Box, CircularProgress } from '@mui/material'
import { usePrevious } from 'services/hooks/usePrevious'
import { LocalOrganizationId } from 'risika-api-response-types/last_update'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'

const COMPANIES = 'companies'
const PERSON = 'person'
export const NORDIC_COMPANIES = ['DK', 'FI', 'NO', 'SE']

const SearchBar = () => {
  const reactIntl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const appShell = useAppShell()
  const { data: searchCountry } = useUserSettingsField('selected_country')
  const searchSelected = appShell.searchActive
  const tableContent = appShell.resultType

  const isGlobalSearch = !NORDIC_COMPANIES.includes(searchCountry as string)

  const [searchText, setSearchText] = useState('')
  const [debouncedSearchText, setDebouncedSearchText] = useState('')
  const {
    data: companySearchData,
    isLoading: isCompanySearchLoading,
    isError: isCompanySearchError,
    error: companySearchError,
  } = useCompanySearch(searchText, searchCountry as AvailableCountries)

  const {
    data: personSearchData,
    isLoading: isPersonSearchLoading,
    isError: isPersonSearchError,
    error: personSearchError,
  } = usePersonSearch(searchText, searchCountry as AvailableCountries)

  const isDataLoading = isCompanySearchLoading || isPersonSearchLoading

  const previousSearchText = usePrevious(searchText)

  const { enqueueSnackbar } = useSnackbar()
  const debounceTime = 300
  useDebounce(
    () => {
      setSearchText(debouncedSearchText)
    },
    debounceTime,
    [debouncedSearchText]
  )

  useEffect(() => {
    if (!searchText) return

    if (isCompanySearchError) {
      enqueueSnackbar(`${companySearchError}`, {
        variant: 'error',
      })
    }

    if (isPersonSearchError) {
      enqueueSnackbar(`${personSearchError}`, {
        variant: 'error',
      })
    }
  }, [isCompanySearchError, enqueueSnackbar, isPersonSearchError, searchText])

  const cleanupSearch = useCallback(
    (state: 'companies' | 'person') => {
      const initialState = {
        search_result: [],
        count: 0,
        rows: {},
      }
      if (state === COMPANIES) {
        return dispatch(setSearchResults(initialState))
      }
      if (state === PERSON) {
        return dispatch(setSearchPerson(initialState))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (!!previousSearchText && searchText === '') {
      cleanupSearch(COMPANIES)
      cleanupSearch(PERSON)
    }
  }, [companySearchData, personSearchData, searchText])

  useEffect(() => {
    if (companySearchData) {
      if (!companySearchData.count && personSearchData && personSearchData.count) {
        cleanupSearch(COMPANIES)
        appShell.setResultType(PERSON)
      }
      dispatch(setSearchResults(companySearchData))
    }
    if (personSearchData) {
      if (!personSearchData.count && companySearchData && companySearchData.count) {
        cleanupSearch(PERSON)
        appShell.setResultType('company')
      }
      dispatch(setSearchPerson(personSearchData))
    }
  }, [companySearchData, personSearchData, dispatch])

  const localId = companySearchData?.search_result?.[0]
    ?.local_organization_id as LocalOrganizationId

  const personId = {
    id: personSearchData?.search_result?.[0]?.personal_id,
    country: personSearchData?.search_result?.[0]?.country,
  }

  const EntitySelector = useCallback(() => {
    if (isDataLoading) {
      return (
        <Box position="relative" display="inline-flex">
          <CircularProgress size={30} />
          {isGlobalSearch && (
            <Box
              position="absolute"
              top="50%"
              left="50%"
              sx={{
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TravelExploreIcon fontSize="large" />
            </Box>
          )}
        </Box>
      )
    }

    return <Selector />
  }, [isDataLoading])

  const goToFirstCompany = (localId: LocalOrganizationId) => {
    const { id } = localId
    history.push(navigation.creditCheck(localId), { id })
  }

  const goToFirstPerson = (personId: {
    id: string | number | undefined
    country: string | undefined
  }) => {
    history.push(navigation.relationOverview(personId))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.currentTarget.blur()
    }

    if (event.key === 'Enter' || event.code === 'Enter') {
      if (tableContent === 'company' && companySearchData?.search_result.length) {
        goToFirstCompany(localId)
        handleCloseSearch()
        event.currentTarget.blur()
      } else if (tableContent === PERSON && companySearchData?.search_result.length) {
        goToFirstPerson(personId)
        handleCloseSearch()
        event.currentTarget.blur()
      }
      event.preventDefault()
    }
  }

  const handleOnFocus = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.currentTarget.select()
    appShell.setSearchActive(true)
  }

  const handleCloseSearch = () => {
    setDebouncedSearchText('')
    appShell.setSearchActive(false)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearchText(event.target.value)
  }

  return (
    <Root data-cy="searchBar">
      <SearchField
        placeholder={reactIntl.formatMessage({
          id: intl.searchBusiness('search-placeholder'),
        })}
        onKeyDown={handleKeyDown}
        onFocus={handleOnFocus}
        leftAdornment={FlagSelect}
        rightAdornment={EntitySelector}
        onChange={handleOnChange}
        data-cy="search"
        value={debouncedSearchText}
      />
      {searchSelected ? (
        <CloseSearch elevation={0} data-cy="close-search-icon">
          <CloseSearchIcon onClick={handleCloseSearch}>
            <FontAwesomeIcon icon={faTimes as Icon} />
          </CloseSearchIcon>
        </CloseSearch>
      ) : null}
    </Root>
  )
}

export default SearchBar
