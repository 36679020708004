import { Stack, Box, Typography } from '@mui/material'
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import CustomShape from './CustomShape'
import { getRatingByAmount } from 'utils/converters'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

const D3KeyFiguresRadarChart = (props) => {
  const reactIntl = useIntl()

  const CustomizedTooltip = (tooltipProps) => {
    if (!tooltipProps.active || !tooltipProps.payload.length === 0) return null
    const type = props.data[tooltipProps.label]?.type ?? null
    const value = props.data[tooltipProps.label]?.value ?? tooltipProps.payload[0].value
    const rating = type ? getRatingByAmount(value, reactIntl.messages) : ''

    return (
      <Box>
        {type === 'ratio' && (
          <Stack
            sx={{
              border: '1px solid',
              borderColor: 'grey.200',
              backgroundColor: 'common.white',
              borderRadius: 1,
            }}
            p={2}
            spacing={1}
          >
            <Typography variant="subtitle1">
              <FormattedMessage
                id={intl.charts('radar-tooltip-current-ratio')}
                values={{ value }}
              />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id={intl.charts('radar-tooltip-explanation')}
                values={{ rating: rating.toLowerCase() }}
              />
            </Typography>
          </Stack>
        )}
        {type === 'score' && (
          <Stack
            sx={{
              border: '1px solid',
              borderColor: 'grey.200',
              backgroundColor: 'common.white',
            }}
            p={2}
            spacing={1}
          >
            <Typography variant="subtitle1">
              {reactIntl.messages[intl.advancedSearch('score')]}: {value / 10}
            </Typography>
            <Typography variant="body2">
              {reactIntl.messages[intl.charts('radar-tooltip-score-since')]}:{' '}
              {props.data[tooltipProps.label]?.duration}
            </Typography>
            {props.data[tooltipProps.label]?.distress ? (
              <Typography variant="body2">
                <FormattedMessage
                  id={intl.charts('radar-tooltip-distress')}
                  values={{
                    value: (props.data[tooltipProps.label]?.distress * 100).toFixed(2),
                  }}
                />
              </Typography>
            ) : null}
          </Stack>
        )}
        {!type && <p className="label">Value: {value}</p>}
      </Box>
    )
  }

  return (
    <ResponsiveContainer height={400}>
      <RadarChart data={props.data}>
        {props.chart.properties.polarGrid && <PolarGrid />}
        {props.chart.properties.PolarAngleAxis && <PolarAngleAxis dataKey="key" />}
        <Radar
          shape={CustomShape}
          name={props.chart.name}
          dataKey="value"
          stroke={props.chart.colors.stroke}
          fill={props.chart.colors.fill}
          fillOpacity={props.chart.colors.fillOpacity}
        />
        {props.chart.properties.tooltip && <Tooltip content={<CustomizedTooltip />} />}
        {props.chart.properties.legend && <Legend />}
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default D3KeyFiguresRadarChart
