import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Company,
  RisikaScore,
  TableCell,
  IntlReactTable,
  Button,
  AddToMonitoringListButton,
} from 'components'
import { navigation } from 'services/navigation'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { safely } from 'utils/safely'
import { clearHistory } from 'store_deprecated'
import { useAccountStatus, useUserSettingsField } from 'services/queries'
import { Typography } from '@mui/material'
import { SearchResultsType } from 'services/api/endpoints/risikaAPI/searchCompany'
import { Address } from 'types/general'
import { LocalId } from 'globalTypes'
import { NORDIC_COMPANIES } from 'components/AppShell/SearchBar'
import { FEATURE_FLAGS } from 'feature-flags/FeatureFlags'
import { getFeatureFlag } from 'feature-flags/getFeatureFlag'

const safeLowerCase = safely((str: string) => str.toLowerCase())

const blacklistedCompanyType = (companyType: string) =>
  ['enk'].includes(safeLowerCase(companyType))

type CompanyTableProps = {
  data: SearchResultsType[]
  handleSelect: (data: boolean) => void
  rowCount: number
}

type Row = {
  original: RowOriginal
}

type RowOriginal = {
  local_organization_id: OrganisationId
}

type OrganisationId = {
  id: string
  country: string
  hash: string
}

const CompanyTable = ({ data, handleSelect, rowCount }: CompanyTableProps) => {
  const history = useHistory()
  let buttonPressed = false
  const accountStatusQuery = useAccountStatus()
  const { data: searchCountry } = useUserSettingsField('selected_country')

  const isGlobalSearch = !NORDIC_COMPANIES.includes(searchCountry as string)
  const isGlobalSearchActive = getFeatureFlag(FEATURE_FLAGS.GLOBAL_SEARCH)

  const hasTypeData = data.some((row) => row.company_type)
  const hasEmployeesData = data.some((row) => row.employees_interval)

  const dispatch = useDispatch()
  React.useEffect(() => {
    safely((window: Window) =>
      window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' || event.key === 'Esc') {
          handleSelect(false)
        }
      })
    )(window)

    return safely((window: Window) => window.removeEventListener('keydown', handleClick))(
      window
    )
  })

  if (!data || !data.map) {
    data = []
  }

  const showStatus = (active: boolean) => {
    if (active === true) {
      return <FormattedMessage id={intl.generic('active')} />
    }

    if (active === false) {
      return <FormattedMessage id={intl.generic('inactive')} />
    }

    return '-'
  }

  const showLocalID = (localID: LocalId) => {
    return (localID?.id?.length || 0) > 11 ? '-' : `${localID.id} (${localID.country})` // TEMP logic
  }

  const showAddress = (address: Address) => {
    const { city, municipality = '' } = address

    if (city) {
      return city.includes(municipality) || !municipality
        ? city
        : `${city} (${municipality})`
    }
  }
  const rowData = data.map((row) => ({
    ...row,
    display_company: (
      <Company
        isActive
        primary={<Typography variant="subtitle1">{row.company_name}</Typography>}
        secondary={
          row.address ? (
            <Typography variant="body2">{showAddress(row.address)}</Typography>
          ) : (
            <FormattedMessage id={intl.generic('missing-address')} />
          )
        }
      />
    ),
    display_score: !isGlobalSearch ? (
      <RisikaScore
        plan={accountStatusQuery.data?.subscription_plan}
        value={row.score}
        // localID={row.local_organization_id}
        displayNumericScore={!blacklistedCompanyType(row.company_type)}
      />
    ) : (
      <Typography variant="body2">
        {row.address.street}
        {row.address.zipcode ? ', ' : null}
        {row.address.zipcode}
      </Typography>
    ),
    display_local_id: (
      <TableCell>
        <Typography variant="body2">{showLocalID(row.local_organization_id)}</Typography>
      </TableCell>
    ),
    display_type: (
      <TableCell>
        <Typography variant="body2">{row.company_type}</Typography>
      </TableCell>
    ),
    display_status: (
      <TableCell>
        <Typography variant="body2">{showStatus(row.active)}</Typography>
      </TableCell>
    ),
    display_employees: (
      <TableCell>
        <Typography variant="body2">
          {row.employees_interval === '1000-999999' ? '1000+' : row.employees_interval}
        </Typography>
      </TableCell>
    ),
  }))

  const handleClickOnRow = (state: string, rowInfo: Row) => ({
    style: { cursor: 'pointer' },
    onMouseUp: () => {
      if (buttonPressed) {
        buttonPressed = false
        return
      }
      handleSelect(false)

      if (isGlobalSearch) {
        return
      }

      if (!rowInfo) {
        return
      }
      dispatch(clearHistory())
      const { id } = rowInfo.original.local_organization_id
      history.push(
        navigation.creditCheck({
          ...rowInfo.original.local_organization_id,
        }),
        { id }
      )
    },
  })

  const handleClick = () => {
    buttonPressed = true
  }

  return (
    <IntlReactTable
      // @ts-expect-error, typescript confused by injectIntl function within table
      data={rowData}
      filterable={false}
      sortable={false}
      className={'-striped -highlight border-0'}
      minRows={rowCount}
      resizable={true}
      getTdProps={handleClickOnRow}
      columns={[
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.searchBusiness('company')} />
            </Typography>
          ),
          accessor: 'display_company',
          minWidth: 400,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              {!isGlobalSearch ? (
                <FormattedMessage id={intl.searchBusiness('risika-score')} />
              ) : (
                <FormattedMessage id={intl.companyInfo('address')} />
              )}
            </Typography>
          ),
          accessor: 'display_score',
          className: 'font-bold justify-center',
          minWidth: 140,
        },
        ...(!isGlobalSearch
          ? [
              {
                Header: (
                  <Typography variant="subtitle2">
                    <FormattedMessage id={intl.searchBusiness('local-id')} />{' '}
                  </Typography>
                ),
                accessor: 'display_local_id',
                className: 'justify-center',
                minWidth: 200,
              },
            ]
          : []),
        ...(!isGlobalSearch
          ? [
              {
                Header: (
                  <Typography variant="subtitle2">
                    <FormattedMessage id={intl.searchBusiness('status')} />{' '}
                  </Typography>
                ),
                accessor: 'display_status',
                className: 'justify-center',
                minWidth: 140,
              },
            ]
          : []),
        ...(hasTypeData
          ? [
              {
                Header: (
                  <Typography variant="subtitle2">
                    <FormattedMessage id={intl.searchBusiness('type')} />
                  </Typography>
                ),
                accessor: 'display_type',
                className: 'justify-center',
                minWidth: 140,
              },
            ]
          : []),
        ...(hasEmployeesData
          ? [
              {
                Header: (
                  <Typography variant="subtitle2">
                    <FormattedMessage id={intl.searchBusiness('employees')} />
                  </Typography>
                ),
                accessor: 'display_employees',
                className: 'justify-center',
                minWidth: 140,
              },
            ]
          : []),
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('monitor')} />{' '}
            </Typography>
          ),
          accessor: 'display_active_companies_count',
          Cell: ({ row }: { row: any }) => (
            <div style={{ margin: '-1rem 0 -1rem 0' }} onMouseDown={handleClick}>
              <AddToMonitoringListButton
                // @ts-expect-error The file is not typed
                buttonText={<FormattedMessage id={intl.generic('monitor')} />}
                color="contrast"
                company={row?._original?.local_organization_id}
                ready={isGlobalSearchActive ? !isGlobalSearch : true}
                ButtonComponent={Button}
              />
            </div>
          ),
          minWidth: 300,
        },
      ]}
    />
  )
}

export default CompanyTable
